<template>
  <div class="container">
    <div v-for="(item, index) in dataList" :key="index" class="marginB">
      <p class="h1">{{ item.title }}</p>
      <p v-for="(citem, cindex) in item.content" :key="cindex">
        {{ citem }}
      </p>
    </div>
  </div>
</template>

<script>
import { agreeData } from "../data/agree";

export default {
  name: "Agree",
  data() {
    return {
      dataList: [],
    };
  },
  methods: {
    fetchData(initLang) {
      if (initLang === "zh") {
        this.dataList = agreeData.cn;
      } else if (initLang === "en") {
        this.dataList = agreeData.en;
      } else {
        this.dataList = agreeData.yn;
      }
    },
  },
  mounted() {
    console.log(this.$route.params.lang);
    let lang = this.$route.params.lang;
    this.fetchData(lang);
  },
};
</script>

<style scoped lang="scss">
.container {
  color: #333;
  padding: 16px;
  font-size: 16px;

  .h1 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 12px;
  }

  p {
    font-size: 14px;
    line-height: 1.8;
    text-align: justify;
    text-align-last: left;
    margin-bottom: 8px;
  }

  .marginB {
    margin-bottom: 12px;
  }
}
</style>

