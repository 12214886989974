let appName = 'BookTok';

var u = navigator.userAgent;
var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
if (isiOS) {
    appName = 'BooksTok'
} else if (isAndroid) {
    appName = 'BookTok'
}

const agreeData = {
    'cn': [
        {
            'title': '一，一般規定',
            'content': [
                `1.1當用戶在註冊過程中單擊“同意”按鈕時，表示用戶已與${appName}公司達成協議，並完全接受該協議下的所有條款。`,
                `1.2成功註冊後，${appName}將為每個用戶提供一個帳戶和密碼。用戶帳戶和密碼由用戶保留，用戶對使用其用戶帳戶執行的所有活動和事件負有法律責任。`,
                '1.3這些服務條款的內容包括在移動閱讀平台上發布的協議以及聲明和規則的文本。包括但不限於版權聲明，“隱私政策”，使用說明。所有聲明和規則與協議的主體具有相同的法律效力。',
                `1.4在使用${appName}提供的服務之前，應仔細閱讀本服務協議。如果您不同意此服務協議，則可以在使用${appName}服務時主動取消${appName}提供的服務，這意味著您了解並完全同意該協議的內容。隨時接受服務協議，並成為${appName}用戶。`
            ]
        },
        {
            'title': '二，服務內容',
            'content': [
                `2.1 ${appName}服務的具體內容由${appName}根據實際情況提供。`,
                `2.2除非本協議中另有明確規定，否則${appName}提供的新功能和服務無條件地適用於這些服務條款。`,
                `2.3除非本協議中另有明確規定，否則${appName}對本服務不承擔任何責任，即，用戶有使用該服務的風險。 ${appName}不保證服務會滿足用戶要求，也不保證服務不會中斷，也不保證服務的及時性，安全性和準確性。`,
                `2.4 ${appName}保留對全部或部分未來服務項目收費的權利。`,
                '2.5為了幫助用戶更好地享受服務，某些服務要求用戶註冊並登錄才能使用它們，例如：充值，購買付費書籍，評論等。',
                `2.6當用戶通過該服務發布，上傳或傳輸內容，連接到該服務，違反這些服務條款或侵犯他人的任何權利時，任何第三方都會發出請求，並且${appName}會受到任何形式的罰款或處罰，用戶同意以適當的方式完全消除對${appName}的不利影響，賠償${appName}及其關聯公司，其關聯公司，代理商或其他關聯公司和員工的損失，並保護他們免受傷害。`
            ]
        },
        {
            'title': '三，使用規則',
            'content': [
                `用戶使用${appName}服務時必須遵守中國相關法律法規。用戶應同意不使用此服務執行任何非法或不正當的活動，包括但不限於以下各項：`,
                '1）從中國境外傳輸技術數據時，必須遵守中國相關法規。',
                '2）在沒有任何非法目的的情況下使用網站服務。',
                '3）請勿干擾或中斷網絡服務。',
                '4）不要在BBS或評論區發布任何政治相關信息。',
                '5）遵守所有網絡協議，規則，程序和慣例。',
                '6）請勿使用本網站危害國家安全，洩露國家秘密或侵犯社會集體的合法權益。',
                '7）請勿使用本網站創建，複製和傳輸以下信息：',
                '1.煽動反對或破壞憲法，法律和行政法規的執行；',
                '2.煽動推翻國家政權，推翻社會主義制度的；',
                '3.煽動國家分裂，破壞民族團結的；',
                '4.煽動民族仇恨，種族歧視和破壞民族團結；',
                '5.捏造或捏造事實，散佈謠言，破壞社會秩序的；',
                '6.促進封建迷信，淫穢，色情，賭博，暴力，謀殺，恐怖主義和消滅犯罪；',
                '7.公開侮辱他人，捏造事實誹謗他人，發動其他惡意攻擊的；',
                '8.損害國家機構的聲譽；',
                '9.違反憲法，法律和其他行政法規的行為；',
                '10.進行商業廣告。',
                `用戶不能傳輸任何消除其他構成犯罪行為的數據，不能傳輸不利的國內條件和相關的國家安全，也不能傳輸任何不符合當地法規，國家法律和國際法的數據。禁止未經授權訪問其他計算機系統。如果用戶的行為不符合上述條款，${appName}將保留取消服務帳戶的權利`
            ]
        },
        {
            'title': '四，隱私政策',
            'content': [
                `保護用戶隱私（尤其是未成年人）是${appName}的一項基本政策。 ${appName}將嚴格管理和保護用戶提供的信息，並使用相應的技術防止用戶個人數據的丟失，被盜或濫用，當用戶使用外部網絡服務或第三方時，非公開註冊信息和內容將存儲在${appName}中。發生以下情況時，${appName}將披露個人信息：`,
                '1.您授權或同意披露；',
                '2.在緊急情況下，為了保護用戶的合法權益或安全和公共利益；',
                '3.根據法律或主管當局的要求提供您的個人信息；',
                '詳情請參閱隱私權政策。'
            ]
        },
        {
            'title': '五，免責聲明',
            'content': [
                `本站所有小說為轉載作品，所有章節均由網友上傳，轉載至本站只是為了宣傳本書讓更多讀者欣賞。如有侵權請聯系管理員進行下架處理，反饋郵箱Booktokapp@gmail.com。`
            ]
        }
    ],
    'yn': [
        {
            'title': '1. Quy tắc chung',
            'content': [
                `1.1 Khi người dùng nhấp vào nút "Đồng ý" trong quá trình đăng ký, điều đó có nghĩa là người dùng đã đạt được thỏa thuận với công ty của ${appName} và chấp nhận hoàn toàn tất cả các điều khoản theo thỏa thuận này.`,
                `1.2 Sau khi đăng ký thành công, ${appName} sẽ cung cấp cho mỗi người dùng một tài khoản và mật khẩu. Tài khoản người dùng và mật khẩu được người dùng lưu giữ, người dùng phải chịu trách nhiệm pháp luật đối với tất cả các hoạt động và sự kiện được thực hiện với tài khoản người dùng của mình.`,
                '1.3 Nội dung của các điều khoản dịch vụ này bao gồm nội dung của thỏa thuận và các tuyên bố và quy tắc được ban hành trên nền tảng đọc di động. Bao gồm nhưng không giới hạn trong thông báo bản quyền, "Chính sách bảo mật", hướng dẫn sử dụng. Tất cả các tuyên bố và quy tắc có hiệu lực pháp lý tương tự như cơ quan của thỏa thuận.',
                `1.4 Trước khi sử dụng các dịch vụ do ${appName} cung cấp, bạn nên đọc kỹ thỏa thuận dịch vụ này. Nếu bạn không đồng ý với thỏa thuận dịch vụ này, bạn có thể chủ động hủy các dịch vụ do ${appName} cung cấp, khi bạn sử dụng dịch vụ ${appName}, có nghĩa là bạn đã hiểu và hoàn toàn đồng ý với nội dung của thỏa thuận dịch vụ này, bao gồm sự thay đổi do ${appName} đối với thỏa thuận dịch vụ bất cứ lúc nào và trở thành người dùng ${appName}.`
            ]
        },
        {
            'title': '2. Nội dung dịch vụ',
            'content': [
                `2.1 Nội dung cụ thể dịch vụ ${appName} được ${appName} cung cấp theo tình hình thực tế.${appName}.`,
                `2.2 Trừ khi có quy định rõ ràng khác trong thỏa thuận này, các chức năng và dịch vụ mới do ${appName} cung cấp đều được áp dụng vô điều kiện với các điều khoản dịch vụ này.`,
                `2.3 Trừ khi có quy định khác rõ ràng trong thỏa thuận này, nếu không có,${appName} sẽ không chịu trách nhiệm đối với dịch vụ, nghĩa là, người dùng phải chịu rủi ro cho việc sử dụng dịch vụ. ${appName} không đảm bảo rằng dịch vụ sẽ đáp ứng các yêu cầu của người dùng,và cũng không đảm bảo rằng dịch vụ không bị gián đoạn,và cũng không đảm bảo tính kịp thời,an toàn,t và độ chính xác của dịch vụ.`,
                `2.4 ${appName} có quyền tính phí cho tất cả hoặc một phần của các mục dịch vụ trong tương lai.`,
                '2.5 Để giúp người dùng tận hưởng dịch vụ tốt hơn, một số dịch vụ yêu cầu người dùng đăng ký và đăng nhập mới sử dụng được, chẳng hạn như: nạp tiền, mua sách trả tiền, bình luận, v.v.',
                `2.6 Khi người dùng đăng, tải lên hoặc truyền nội dung thông qua dịch vụ này, kết nối với dịch vụ này, vi phạm các điều khoản dịch vụ này hoặc vi phạm bất kỳ quyền nào của người khác, bất kỳ bên thứ ba nào đưa ra yêu cầu và ${appName} bị phạt tiền hoặc hình phạt bất kỳ hình thức nào, người dùng đồng ý loại trừ hoàn toàn các tác động bất lợi đối với ${appName} theo cách thích hợp, bù đắp tổn thất cho ${appName} và các chi nhánh, chi nhánh, đại lý hoặc các đối tác và nhân viên khác của họ,và bảo vệ họ khỏi thiệt hại.`
            ]
        },
        {
            'title': '3. Quy tắc sử dụng',
            'content': [
                `Người dùng phải tuân thủ luật pháp và quy định có liên quan của Trung Quốc khi sử dụng dịch vụ ${appName}. Người dùng nên đồng ý không sử dụng dịch vụ này để thực hiện bất kỳ hoạt động bất hợp pháp hoặc không chính đáng nào, bao gồm nhưng không giới hạn ở những điều sau:`,
                '1)Khi chuyển dữ liệu kỹ thuật từ Trung Quốc ra bên ngoài, nó phải tuân thủ các quy định có liên quan của Trung Quốc.',
                '2)Sử dụng các dịch vụ trang web không có mục đích bất hợp pháp.',
                '3)Không can thiệp hoặc làm gián đoạn dịch vụ mạng.',
                '4)Không đăng bất kỳ thông tin liên quan đến chính trị trong BBS hoặc khu vực bình luận.',
                '5)Tuân thủ tất cả các thỏa thuận mạng, quy định, quy trình và thực hành sử dụng dịch vụ mạng.',
                '6)Không sử dụng trang này để gây nguy hiểm cho an ninh quốc gia, tiết lộ bí mật nhà nước hoặc xâm phạm quyền và lợi ích hợp pháp của tập thể xã hội.',
                '7)Không sử dụng trang web này để tạo, sao chép và truyền các thông tin sau:',
                '1. Kích động để chống lại hoặc làm suy yếu việc thực thi Hiến pháp, pháp luật và các quy định hành chính;',
                '2. Xúi giục lật đổ quyền lực nhà nước và lật đổ hệ thống xã hội chủ nghĩa;',
                '3. Những kẻ kích động chia rẽ đất nước và phá hoại đoàn kết dân tộc;',
                '4. Kích động hận thù dân tộc, phân biệt sắc tộc và phá hoại đoàn kết dân tộc;',
                '5. Chế tạo hoặc xuyên tạc sự thật, lan truyền tin đồn và gây rối trật tự xã hội;',
                '6. Thúc đẩy sự mê tín phong kiến, tục tĩu, khiêu dâm, đánh bạc, bạo lực, giết người, khủng bố và xóa bỏ tội ác;',
                '7. Những kẻ công khai lăng mạ người khác hoặc bịa đặt sự thật để nói xấu người khác, hoặc thực hiện các cuộc tấn công độc hại khác;',
                '8. Làm tổn hại uy tín của các cơ quan nhà nước;',
                '9. Các hành vi vi phạm Hiến pháp và pháp luật và các quy định hành chính khác;',
                '10. Tiến hành quảng cáo thương mại.',
                `Người dùng không thể truyền bất kỳ dữ liệu nào loại bỏ các hành vi khác để thành một hành vi tội phạm, không thể truyền các điều kiện bất lợi trong nước và liên quan đến an ninh quốc gia và không thể truyền bất kỳ dữ liệu nào không tuân thủ các quy định của địa phương, luật pháp quốc gia và luật pháp quốc tế. Truy cập trái phép vào các hệ thống máy tính khác đều bị cấm. Nếu hành vi của người dùng không đáp ứng các điều khoản trên, ${appName} sẽ có quyền hủy tài khoản dịch vụ của người dùng.`
            ]
        },
        {
            'title': '4. Chính sách bảo mật',
            'content': [
                `Bảo vệ quyền riêng tư của người dùng (đặc biệt là trẻ chưa thành niên) là một chính sách cơ bản của ${appName}. ${appName} sẽ quản lý và bảo vệ nghiêm ngặt thông tin do người dùng cung cấp,và sử dụng các công nghệ tương ứng để ngăn ngừa mất, đánh cắp hoặc lạm dụng dữ liệu cá nhân của người dùng.Đảm bảo rằng nó sẽ không đơn phương cung cấp thông tin đăng ký và nội dungkhông công khai được lưu trữ trong ${appName} khi người dùng sử dụng dịch vụ mạng cho bên ngoài hoặc bên thứ ba nào.${appName} sẽ tiết lộ thông tin cá nhận khi xảy ra các tình huống sau:`,
                '1. Bạn cho phép hoặc đồng ý tiết lộ;',
                '2. Trong trường hợp khẩn cấp, để bảo vệ các quyền hợp pháp của người dùng hoặc an toàn và lợi ích công cộng;',
                '3. Cung cấp thông tin cá nhân của bạn theo quy định của pháp luật hoặc yêu cầu của cơ quan có thẩm quyền;',
                'Xem "Chính sách bảo mật" để biết chi tiết.'
            ]
        },
        {
            'title': '5. Tuyên bố từ chối trách nhiệm',
            'content': [
                `Tất cả tiểu thuyết trên trang web này đều là tác phẩm chuyển tải và tất cả các chương đều do cư dân mạng tải lên. Nếu có bất kỳ vi phạm nào, vui lòng liên hệ với người quản lý để xóa bỏ, đồng thời gửi email phản hồi đến Booktokapp@gmail.com.`
            ]
        }
    ],
    'en': [
        {
            'title': '1. General regulations',
            'content': [
                `1.1 When the user clicks the "Agree" button during the registration process, it means that the user has reached an agreement with ${appName} and fully accepts all the terms under the agreement.`,
                `1.2 After successful registration, ${appName} will provide each user with an account and password. The user account and password are retained by the user, and the user is legally responsible for all activities and events performed using his user account.`,
                '1.3 The content of these terms of service includes the text of the agreement, statement and rules published on the mobile reading platform. Including but not limited to copyright notice, "privacy policy", instructions for use. All statements and rules have the same legal effect as the subject of the agreement.',
                `1.4 Before using the services provided by ${appName}, you should carefully read this service agreement. If you do not agree to this service agreement, you can voluntarily cancel the service provided by ${appName} when using the ${appName} service, which means that you understand and fully agree to the content of the agreement. Accept the service agreement at any time and become a ${appName} user.`
            ]
        },
        {
            'title': '2. Service content',
            'content': [
                `2.1 The specific content of the ${appName} service is provided by ${appName} according to the actual situation.`,
                `2.2 Unless expressly stated otherwise in this agreement, the new features and services provided by ${appName} are unconditionally applicable to these terms of service.`,
                `2.3 Unless otherwise expressly provided in this agreement, ${appName} does not assume any responsibility for the service, that is, the user has the risk of using the service. ${appName} does not guarantee that the service will meet user requirements, nor does it guarantee that the service will not be interrupted, nor does it guarantee the timeliness, safety and accuracy of the service.`,
                `2.4 ${appName} reserves the right to charge for all or part of future service items.`,
                '2.5 In order to help users better enjoy the services, some services require users to register and log in to use them, for example: recharge, purchase paid books, comment, etc.',
                `2.6 When the user publishes, uploads or transmits content through the service, connects to the service, violates these terms of service or violates any rights of others, any third party will make a request, and ${appName} will be subject to any form of fines or penalties. The user agrees Completely eliminate the adverse effects on ${appName} in an appropriate way, compensate ${appName} and its affiliates, its affiliates, agents or other affiliates and employees for losses, and protect them from harm.`
            ]
        },
        {
            'title': '3. Use-rules',
            'content': [
                `Users must abide by relevant Chinese laws and regulations when using ${appName} services. Users should agree not to use this service to perform any illegal or improper activities, including but not limited to the following:`,
                '1) When transferring technical data from outside China, relevant Chinese regulations must be complied with.',
                '2) Use website services without any illegal purpose.',
                '3) Please do not interfere with or interrupt network services.',
                '4) Do not post any political related information in the BBS or comment area.',
                '5) Comply with all network protocols, rules, procedures and conventions.',
                '6) Please do not use this website to endanger national security, reveal national secrets or infringe on the legal rights and interests of the social collective.',
                '7) Do not use this website to create, copy and transmit the following information:',
                '1. Inciting opposition or undermining the implementation of the Constitution, laws and administrative regulations;',
                '2. Inciting to overthrow state power and overthrow the socialist system;',
                '3. Inciting division of the country and undermining national unity;',
                '4. Inciting ethnic hatred, racial discrimination and undermining ethnic unity;',
                '5. Fabricating or fabricating facts, spreading rumors, and disrupting social order;',
                '6. Promote feudal superstition, obscenity, pornography, gambling, violence, murder, terrorism and eliminate crime;',
                '7. Publicly insulting others, fabricating facts to slander others, or launching other malicious attacks;',
                '8. Damage the reputation of national institutions;',
                '9. Violation of the Constitution, laws and other administrative regulations;',
                '10. Carry out commercial advertising.',
                'Users cannot transmit any data that eliminates other criminal acts, cannot transmit unfavorable domestic conditions and related national security, and cannot transmit any data that does not comply with local regulations, national laws and international laws. Unauthorized access to other computer systems is prohibited. If the user’s behavior does not comply with the above terms, ${appName} reserves the right to cancel the service account.'
            ]
        },
        {
            'title': '4. Privacy policy',
            'content': [
                `Protecting user privacy (especially minors) is a basic policy of ${appName}. ${appName} will strictly manage and protect the information provided by users, and use corresponding technologies to prevent the loss, theft or abuse of users’ personal data. When users use external network services or third parties, non-public registration information and content will be stored in ${appName}. . When the following situations occur, ${appName} will disclose personal information:`,
                '1. You authorize or agree to the disclosure;',
                '2. In an emergency, in order to protect the legitimate rights and interests or safety and public interest of users;',
                '3. Provide your personal information in accordance with the requirements of the law or the competent authority;',
                'Please refer to the privacy policy for details.'
            ]
        },
        {
            'title': '5. Disclaimer',
            'content': [
                `All novels on this app are reproduced works, and all chapters are uploaded by netizens. Reproduced to this app is just to promote the book for more readers to appreciate. If there is any infringement, please contact the administrator for removal, and send the feedback email to Booktokapp@gmail.com.`
            ]
        }
    ],
};

export { agreeData };